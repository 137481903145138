import { Crud } from "."

export class OnlineStatApi extends Crud {
    constructor() {
        super('online_stat')
    }
    async getOpenedSessions() {
        return await this.query(`getOpenedSessions`, 'post')
    }
}
