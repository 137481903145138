import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

import { checkAuthStatus } from './utils/authService';
import UserContext from './context/UserContext';

import AdminPageLayout, { EmptyLayout, AdminLayoutBars } from './components/layout/AdminPageLayout';


const NotFoundPage = lazy(() => import('./pages/404'));
const Login = lazy(() => import('./pages/Login'));
const Home = lazy(() => import('./pages/Home'));
const Dashboard = lazy(() => import('./pages/Dashboard'));


const AdvertisersIndex = lazy(() => import('./pages/advertisers/Index'));
const AdvertisersEdit = lazy(() => import('./pages/advertisers/AdvertisersEdit'));
const AnalyticsAdIndex = lazy(() => import('./pages/analytics/advertisers/AdvertisersIndex'));
const AnalyticsGroupIndex = lazy(() => import('./pages/analytics/groups/AnalyticsGroupsIndex'));
const GroupsIndex = lazy(() => import('./pages/groups/GroupIndex'));
import AnalyticsView from './pages/analytics/general/AnalyticsView';
const AnalyticsAdView = lazy(() => import('./pages/analytics/advertisers/AdvertisersView'));
const AnalyticsGroupView = lazy(() => import('./pages/analytics/groups/AnalyticsGroupsView'));
const AnalyticsVisitIndex = lazy(() => import('./pages/analytics/visitors/VisitorsIndex'));
const AnalyticsVisitView = lazy(() => import('./pages/analytics/visitors/VisitorsView'));
const UsersIndex = lazy(() => import('./pages/users/UsersIndex'));
const UsersEdit = lazy(() => import('./pages/users/UsersEdit'));
const TActionsIndex = lazy(() => import('./pages/target_actions/TActionsIndex'));
const TActionsEdit = lazy(() => import('./pages/target_actions/TActionsEdit'));
const AntifraudIndex = lazy(() => import('./pages/antifraud/AntifraudIndex'));
const Support = lazy(() => import('./pages/Support'));
const Settings = lazy(() => import('./pages/Settings'));
const Adminlogs = lazy(() => import('./pages/adminlogs/AdminlogsIndex'));

const KnowledgeBaseAnAdv = lazy(() => import('./pages/knowledgebase/AnAdv'));
const KnowledgeBaseAdv = lazy(() => import('./pages/knowledgebase/Adv'));
const KnowledgeBaseGroup = lazy(() => import('./pages/knowledgebase/Groups'));
const KnowledgeBaseTA = lazy(() => import('./pages/knowledgebase/TA'));
const KnowledgeBaseIndex = lazy(() => import('./pages/knowledgebase/Index'));


import './style.css';
import { ParamsProvider } from './provider/ParamsProvider';

const default_url = "/analytics";

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchAuthStatus = async () => {
      const user = await checkAuthStatus();
      setIsAuthenticated(!!user.login);
      setUser(user);
    };
    fetchAuthStatus();
  }, []);

  const logoutUser = () => {
    setUser({});
    setIsAuthenticated(false);
  };

  const loginUser = async () => {
    const user = await checkAuthStatus();
    setIsAuthenticated(!!user.login);
    setUser(user);
  };
  if (isAuthenticated === null) return <></>;
  return (
    <UserContext.Provider value={{ user, loginUser, logoutUser }}>
      <ParamsProvider>
        <Suspense fallback={<div style={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>Loading...</div>}>
          <Routes>
            <Route element={<EmptyLayout />}>
              <Route path="target_action/frame" element={isAuthenticated ? <TActionsEdit frame={true} /> : <Navigate to="/login" replace />} />
            </Route>
            <Route path="/" element={<Outlet />}>
              <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate to="/" replace />} />
              <Route element={<AdminLayoutBars />}>
                <Route path="kb/an/adv" element={isAuthenticated ? <KnowledgeBaseAnAdv /> : <Navigate to="/login" replace />} />
                <Route path="kb/adv" element={isAuthenticated ? <KnowledgeBaseAdv /> : <Navigate to="/login" replace />} />
                <Route path="kb/ta" element={isAuthenticated ? <KnowledgeBaseTA /> : <Navigate to="/login" replace />} />
                <Route path="kb/groups" element={isAuthenticated ? <KnowledgeBaseGroup /> : <Navigate to="/login" replace />} />
                <Route path="kb" element={isAuthenticated ? <KnowledgeBaseIndex /> : <Navigate to="/login" replace />} />
              </Route>
              <Route element={<AdminPageLayout />}>
                <Route path="/" element={isAuthenticated ? <Navigate to={default_url} replace /> : <Navigate to="/login" replace />} />
                <Route path="dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" replace />} />
                <Route path="advertisers" element={isAuthenticated ? <AdvertisersIndex /> : <Navigate to="/login" replace />} />
                <Route path="groups" element={isAuthenticated ? <GroupsIndex /> : <Navigate to="/login" replace />} />
                <Route path="advertiser/add" element={isAuthenticated ? <AdvertisersEdit /> : <Navigate to="/login" replace />} />
                <Route path="advertiser/:id" element={isAuthenticated ? <AdvertisersEdit /> : <Navigate to="/login" replace />} />
                <Route path="analytics" element={isAuthenticated ? <AnalyticsView /> : <Navigate to="/login" replace />} />
                <Route path="analytics/advertisers" element={isAuthenticated ? <AnalyticsAdIndex /> : <Navigate to="/login" replace />} />
                <Route path="analytics/advertiser/view/:id" element={isAuthenticated ? <AnalyticsAdView /> : <Navigate to="/login" replace />} />
                <Route path="analytics/groups" element={isAuthenticated ? <AnalyticsGroupIndex /> : <Navigate to="/login" replace />} />
                <Route path="analytics/groups/view/:id" element={isAuthenticated ? <AnalyticsGroupView /> : <Navigate to="/login" replace />} />
                <Route path="analytics/visitors" element={isAuthenticated ? <AnalyticsVisitIndex /> : <Navigate to="/login" replace />} />
                <Route path="analytics/visitor/view/:id" element={isAuthenticated ? <AnalyticsVisitView /> : <Navigate to="/login" replace />} />
                <Route path="users" element={isAuthenticated ? <UsersIndex /> : <Navigate to="/login" replace />} />
                <Route path="user/add" element={isAuthenticated ? <UsersEdit /> : <Navigate to="/login" replace />} />
                <Route path="user/:id" element={isAuthenticated ? <UsersEdit /> : <Navigate to="/login" replace />} />
                <Route path="target_actions" element={isAuthenticated ? <TActionsIndex /> : <Navigate to="/login" replace />} />
                <Route path="target_action/:id" element={isAuthenticated ? <TActionsEdit /> : <Navigate to="/login" replace />} />
                <Route path="target_action/add" element={isAuthenticated ? <TActionsEdit /> : <Navigate to="/login" replace />} />
                <Route path="antifraud" element={isAuthenticated ? <AntifraudIndex /> : <Navigate to="/login" replace />} />
                <Route path="support" element={isAuthenticated ? <Support /> : <Navigate to="/login" replace />} />
                <Route path="settings" element={isAuthenticated ? <Settings /> : <Navigate to="/login" replace />} />
                <Route path="adminlogs" element={isAuthenticated ? <Adminlogs /> : <Navigate to="/login" replace />} />
                <Route path="*" element={<NotFoundPage />} />
              </Route>
            </Route>
          </Routes>
        </Suspense>
      </ParamsProvider>
    </UserContext.Provider>
  );
}