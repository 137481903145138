import { useCallback, useState } from "react";
import { capitalizeFirstLetter, snakeToCamelCase } from "../../utils";
function getSetterName(state_name) {
    return 'set' + capitalizeFirstLetter(snakeToCamelCase(state_name))
}
export function makeStates(default_states) {
    let states = {}
    let setters = {}
    for (let state_name of Object.keys(default_states)) {
        let [state, setState] = useState(default_states[state_name])
        states[state_name] = state
        let setter_name = getSetterName(state_name)
        setters[setter_name] = useCallback((v) => { setState(v) }, [setState])
    }
    return [states, setters]
}
export function falseableToEmptyString(key, value) {
    return value || '';
}
export function setStates(setters, object, transform = (key, value) => value) {
    for (let key of Object.keys(object)) {
        let setter_name = getSetterName(key)
        if (setter_name in setters) {
            let value_to_set = transform(key, object[key])
            // console.log([key,setter_name,object[key],value_to_set])
            setters[setter_name](value_to_set)
        }
    }
}