import { useMemo, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import { useLanguageContext } from '../../../context/LanguageContext.js';
import { AnalyticsShowProvider, useAnalyticsViewContext } from './AnalyticsViewProvider.js';

import { Box, Button, Sheet, Table, Typography, Skeleton, CircularProgress, Tooltip } from '@mui/joy';
import { AccordionGroup, Accordion, accordionClasses, AccordionDetails, AccordionSummary } from '@mui/joy';

import Title from '../../../components/layout/Title.js';
import Bread from '../../../components/layout/BreadCrumbs.js';
import { PieChartWithLegend } from '../../../components/PieChartWithLegend.js';
import { Average, Perday } from '../../../components/Chart.js';
import { PieChartSkeleton } from '../../../components/skeletons/PieChartsBlockSkeleton.js';
import { SelectPeriod } from '../../../components/SelectPeriod.js';

import { useInfo } from '../../../utils/hooks/useInfo.js';
import { formatPrc } from '../../../utils.js';
import { executeWithIntervalAndTimeoutAndFill } from "../../../utils/hooks/pending.js"
import { GroupApi } from "../../../utils/crud/group_api.js";

import moment from 'moment';
import { getFromParam, getToParam, useDateFromParam, useDateToParam } from '../../../utils/hooks/paramsHelpers.js';
import { OnlineStatApi } from '../../../utils/crud/online_stat_api.js';
import { usePending } from '../../../utils/hooks/usePending.js';
import { rethrow } from '../../../utils/crud/index.js';
import { ErrorBlock } from '../../../components/ErrorBlock.js';
import { ViewField } from '../../../components/ViewField.js';
import { useParamsContext } from '../../../provider/ParamsProvider.js';
const crud = new GroupApi();


async function downloadBlob(blob, filename) {
	let url = window.URL.createObjectURL(blob)
	let a = document.createElement("a");
	document.body.appendChild(a);
	a.style = "display: none";
	a.href = url;
	a.download = filename;
	a.click();
	window.URL.revokeObjectURL(url);
	a.remove();
}
async function downloadReport(from, to) {
	try {
		let res = await fetch(`${process.env.REACT_APP_BACK_URL}/admin_sitegroup/downloadReportAll`, {
			method: "POST",
			credentials: "include",
			headers: {
				"x-reqtype": "admin",
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ from, to })
		})
		if (!res.ok) {
			throw new Error('Bad status code');
		}
		let blob = await res.blob()
		await downloadBlob(blob, "report.xlsx")
	}
	catch (e) {
		console.error(e)
		throw new Error(`Can't download report`)
	}
}
function getStatsPending() {
	return {
		uniq_chart: 'pending',
		perday: 'pending',

		top_country: 'pending',
		top_lang: 'pending',

		avg_visit_time: 'pending',
		avg_effective_time: 'pending',
		visitors_count: 'pending',
		bounce_rate: 'pending',
		last_update: 'pending',
		depth: 'pending',
		bot_count: 'pending',
		cart_count: 'pending',
	}
}
const TitleBlock = () => {
	let {
		loadingBaseInfo,
		loading,
		setLoading,
		statblocks,
		setStatblocks,
	} = useAnalyticsViewContext();

	const { t } = useLanguageContext();
	const { id } = useParams();
	const info = useInfo();
	const [from] = useDateFromParam();
	const [to] = useDateToParam();
	let title = `${t("All analytics")}`;
	let [loadingex, setLoadingex] = useState(false)
	const updateBlocks = async () => {
		setStatblocks(getStatsPending());
		setLoading(true);
		let ut = Date.now();
		try {
			await executeWithIntervalAndTimeoutAndFill((update) => crud.findAnalyticsAll({ update, from, to }), 2000, 300000, statblocks, setStatblocks, 'statblocks');
		} catch (error) {
			console.error(error)
			info(error.message, "danger");
		}
		setLoading(false);
	}
	if (loadingBaseInfo) return <TitleBlockSkeleton />
	return (
		<Title>
			<Typography level="h2" component="h1">
				{title}
			</Typography>
			<Box display="flex" alignItems="center" gap={1} flexDirection={{ xs: 'column', sm: 'row' }} width={{ xs: '100%', sm: 'max-content' }} >
				<Button sx={{ width: { xs: "100%", sm: "fit-content" } }} size='sm' variant="outlined" loadingIndicator={<CircularProgress />} disabled={loading} loading={loading} onClick={updateBlocks}>{t('Update')}</Button>
				<Button sx={{ width: { xs: "100%", sm: "fit-content" } }} disabled={loadingex || loading} size='sm' variant="outlined" onClick={
					() => (setLoadingex(true), downloadReport(from, to).catch(e => info(e.message, "danger")).finally(() => { setLoadingex(false) }))
				}>{t('Export analytics')}</Button>
			</Box>
		</Title>
	);
};

const TopBlock = () => {
	let {
		statblocks,
		loading,
	} = useAnalyticsViewContext();
	const { t } = useLanguageContext();
	return (
		<>
			{
				loading || (statblocks?.last_update && statblocks.last_update === 'pending') ? <ViewField name={t("Last update")} value={<Skeleton loading variant="text" sx={{ width: '100px' }}></Skeleton>} flexDirection="row" />
					: <ViewField name={t("Last update")} value={statblocks?.last_update && statblocks.last_update !== 'pending' ? moment.utc(statblocks.last_update).local().format('DD.MM.YYYY HH:mm:ss') : ''} flexDirection="row" />
			}
		</>
	);
};

const tableDescription = [
	{
		name: "Unique users",
		td: (statblocks) => <td key="visitors_count">{statblocks.visitors_count}</td>,
		deps: ["visitors_count"]
	},
	{
		name: "Cart",
		td: (statblocks) => <td key="cart_count">{statblocks.cart_count}</td>,
		deps: ["cart_count"]
	},
	{
		name: "Cart %",
		td: (statblocks) => <td key="cart_count_prc">{formatPrc(statblocks.cart_count * 100 / statblocks.visitors_count / 100)}</td>,
		deps: ["cart_count", "visitors_count"]
	},
	{
		name: "Page depth",
		td: (statblocks) => <td key="depth">{statblocks.depth?.toFixed(4)}</td>,
		deps: ["depth"]
	},
	{
		name: "Average visit duration",
		td: (statblocks) => <td key="avg_visit_time">{statblocks.avg_visit_time?.toFixed(2)}</td>,
		deps: ["avg_visit_time"]
	},
	{
		name: "Average effective time",
		td: (statblocks) => <td key="avg_effective_time">{statblocks.avg_effective_time ? statblocks.avg_effective_time?.toFixed(2) : "No data"}</td>,
		deps: ["avg_effective_time"]
	},
	{
		name: "Bot",
		td: (statblocks) => <td key="bot_count">{statblocks.bot_count}</td>,
		deps: ["bot_count"]
	},
	{
		name: "Bot %",
		td: (statblocks) => <td key="bot_count_prc">{formatPrc(statblocks.bot_count * 100 / statblocks.visitors_count / 100)}</td>,
		deps: ["bot_count", "visitors_count"]
	},
	{
		name: "Bounce rate",
		td: (statblocks) => <td key="bounce_rate">{formatPrc(statblocks.bounce_rate)}</td>,
		deps: ["bounce_rate"]
	}
];
const table_deps = [...new Set(tableDescription.map(({ deps }) => deps).flat())];

const TableWrapper = ({ children }) => {
	const { t } = useLanguageContext();
	return (
		<Sheet
			className="OrderTableContainer"
			variant="outlined"
			sx={{
				width: '100%',
				borderRadius: 'sm',
				flexShrink: 1,
				overflow: 'auto',
				minHeight: 0,
				my: 2
			}}
		>
			<Table
				aria-label="analytics table"
				sx={{
					'--TableCell-headBackground': 'var(--joy-palette-background-level1)',
					'--Table-headerUnderlineThickness': '1px',
					'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
					'--TableCell-paddingY': '4px',
					'--TableCell-paddingX': '8px',
					width: 'auto',
					minWidth: '100%',
				}}
			>
				<thead>
					<tr>
						{tableDescription.map(({ name }) => <th key={name}>{t(name)}</th>)}
					</tr>
				</thead>
				<tbody><tr>{children}</tr></tbody>
			</Table>
		</Sheet>
	)
};


const TableBlock = () => {
	let { statblocks } = useAnalyticsViewContext();
	let have_error = table_deps.some(dep => statblocks?.[dep] == 'error');
	let have_pending = table_deps.some(dep => statblocks?.[dep] == 'pending');
	if (have_error) {
		return <ErrorBlock width={undefined} />;
	}
	if (!statblocks || have_pending) return <TableBlockSkeleton />
	return (
		<TableWrapper>
			{tableDescription.map(({ td }) => td(statblocks))}
		</TableWrapper>
	)
}


const LangTop = () => {
	const { t } = useLanguageContext();
	let { statblocks } = useAnalyticsViewContext();
	const top_lang = Array.isArray(statblocks?.top_lang) ? statblocks.top_lang.sort((a, b) => b.prc - a.prc) : [];
	const langData = useMemo(() => top_lang.map((row) => ({
		legendLabel: <>{row.lang} - {row.count} ({formatPrc(row.prc)})</>,
		label: row.lang,
		count: row.count != undefined ? row.count : '',
		prc: row.prc,
	})), [top_lang]);

	let value = top_lang?.length == 0 ? <Typography>No languages found</Typography> : <PieChartWithLegend data={langData} />
	if (statblocks?.top_lang === 'error' || (statblocks?.top_lang === 'pending' && Object.values(statblocks)?.some(el => el === 'error'))) value = <ErrorBlock />;
	else if (!statblocks || statblocks?.top_lang === 'pending') value = <PieChartSkeleton />

	return (
		<Box component={'div'} display={'flex'} flexDirection={'column'} gap={2.5}>
			<ViewField
				name={<Tooltip title="Top 10 Languages by Page Views."><span>{t('Top 10 languages')}</span></Tooltip>}
				value={value}
			/>
		</Box>
	);
}
const CountryTop = () => {
	const { t } = useLanguageContext();
	let { statblocks } = useAnalyticsViewContext();
	let top_country = Array.isArray(statblocks?.top_country) ? statblocks.top_country.sort((a, b) => b.prc - a.prc) : [];
	const countryData = useMemo(() => top_country.map((row) => ({
		legendLabel: <><span className={`fi fi-${row.country.toLowerCase()}`}></span> {row.country} - ({formatPrc(row.prc)})</>,
		label: row.country,
		count: row.count,
		prc: row.prc,
	})), [top_country]);

	let value = top_country?.length == 0 ? <Typography>No countries found</Typography> : <PieChartWithLegend data={countryData} />
	if (statblocks?.top_country === 'error' || (statblocks?.top_country === 'pending' && Object.values(statblocks)?.some(el => el === 'error'))) value = <ErrorBlock />;
	else if (!statblocks || statblocks?.top_country === 'pending') value = <PieChartSkeleton />

	return (
		<Box component={'div'} display={'flex'} flexDirection={'column'} gap={2.5}>
			<ViewField
				name={<Tooltip title="Top 10 Countries by Page Views."><span>{t('Top 10 countries')}</span></Tooltip>}
				value={value}
			/>
		</Box>
	);
}
const ChartAverage = () => {
	const { t } = useLanguageContext();
	let { statblocks } = useAnalyticsViewContext();
	let chart = Array.isArray(statblocks?.uniq_chart) ? statblocks.uniq_chart : [];
	const data = useMemo(() => chart.map((row) => parseInt(row.visitors)), [chart]);
	const xdata = useMemo(() => chart.map((row) => moment(row.day).format('DD.MM.YYYY')), [chart]);

	let value = <Average data={data} xdata={xdata} />
	if (statblocks?.uniq_chart === 'error' || (statblocks?.uniq_chart === 'pending' && Object.values(statblocks)?.some(el => el === 'error'))) value = <ErrorBlock py="50px" />
	else if (!statblocks || statblocks?.uniq_chart === 'pending') value = <Skeleton loading variant="rectangular" style={{ width: '100%', height: '200px' }}></Skeleton>

	return (
		<Box component={'div'} display={'flex'} flexDirection={'column'} gap={2.5}>
			<ViewField
				name={<Tooltip title="Cumulative total of unique visitors"><span>{t('Cumulative total of unique visitors')}</span></Tooltip>}
				value={value}
			/>
		</Box>
	);
}
const ChartPerday = () => {
	const { t } = useLanguageContext();
	let { statblocks } = useAnalyticsViewContext();
	let chart = Array.isArray(statblocks?.perday) ? statblocks.perday : [];
	const data = useMemo(() => chart.map((row) => parseInt(row.visitors)), [chart]);
	const xdata = useMemo(() => chart.map((row) => moment(row.day).format('DD.MM.YYYY')), [chart]);

	let value = <Perday data={data} xdata={xdata} />
	if (statblocks?.perday === 'error' || (statblocks?.perday === 'pending' && Object.values(statblocks)?.some(el => el === 'error'))) value = <ErrorBlock py="50px" />;
	else if (!statblocks || statblocks?.perday === 'pending') value = <Skeleton loading variant="rectangular" style={{ width: '100%', height: '200px' }}></Skeleton>

	return (
		<Box component={'div'} display={'flex'} flexDirection={'column'} gap={2.5}>
			<ViewField
				name={<Tooltip title="Unique visitors per day"><span>{t('Unique visitors per day')}</span></Tooltip>}
				value={value}
			/>
		</Box>
	);
}

const Period = () => {
	const { t } = useLanguageContext();
	const { statblocks, setStatblocks, setLoading, loading } = useAnalyticsViewContext();
	const info = useInfo();
	const { flushParams } = useParamsContext();

	const updateBlocks = async (from, to) => {
		await flushParams();
	}
	return (
		<Box component={'div'} display={'flex'} flexDirection={'column'} gap={2.5}>
			<ViewField
				name={<Tooltip title="By default, data is displayed for the entire period"><span>{t('You can select period by date')}</span></Tooltip>}
				value={<SelectPeriod loading={loading} action={updateBlocks} label={t('Updating may take a few minutes!')} />}
			/>
		</Box>
	);
}
const online_stat_api = new OnlineStatApi();
const OnlineStat = () => {
	const { t } = useLanguageContext();
	const [online, setOnline] = useState(null);
	const [error, setError] = useState(false);
	usePending(async () => {
		let online = await online_stat_api.getOpenedSessions();
		if (online.is_error) {
			setError(true);
			return true;
		}
		setOnline(online);
		return false;
	}, 1000);
	let value = typeof online == "number" ? <Typography>{online}</Typography> : <Skeleton variant='text' width={100}></Skeleton>;
	if (error) value = <ErrorBlock py="1px" width='300px' />
	return <ViewField
		flexDirection="row"
		name={<Tooltip title="Number of currently opened sessions"><span>{t('Online now')}</span></Tooltip>}
		value={value}
	/>
}
const View = () => {
	const { t } = useLanguageContext();
	return (
		<>
			<Bread path={[{ name: t("Analytics") }]} />
			<TitleBlock />
			<Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}  >
				<OnlineStat />
				<TopBlock />
				<Period />
				<ChartAverage />
				<ChartPerday />
				<AccordionGroup disableDivider sx={(theme) => ({
					[`& .${accordionClasses.root}`]: {
						bgcolor: 'background.level1',
						borderRadius: 'md',
						border: '1px solid',
						borderColor: 'var(--joy-palette-divider)',
						marginTop: '0.5rem',
						transition: '0.2s ease',
						'& button:not([aria-expanded="true"])': {
							transition: '0.2s ease',
						},
						'& button:hover': {
							background: 'transparent',
						},
					},
					[`& .${accordionClasses.root}.${accordionClasses.expanded}`]: {
						bgcolor: 'var(--joy-palette-background-body)',
						borderRadius: 'md',
						border: '1px solid',
						borderColor: 'var(--joy-palette-divider)',
						'& button[aria-expanded="true"]': {
							bgcolor: 'background.level1',
							fontSize: 'sm',
							marginBottom: '1rem',
						},
					},
				})}>
					<Accordion>
						<AccordionSummary>Traffic distribution:</AccordionSummary>
						<AccordionDetails>
							<Box component="div" sx={{ display: "flex", gap: "6rem", flexDirection: { xs: 'column', md: 'row' }, flexWrap: "wrap", maxHeight: { xs: 'auto', xl: '712px' } }}>
								<CountryTop />
								<LangTop />
							</Box>
						</AccordionDetails>
					</Accordion>
				</AccordionGroup>
				<TableBlock />
			</Box>
		</>
	);
};

const AdvertisersView = () => {
	return <AnalyticsShowProvider>
		<View />
	</AnalyticsShowProvider>
};

/**
 * Прелоадер для блока с заголовком
 */
const TitleBlockSkeleton = () => {
	const { t } = useLanguageContext();
	return (
		<Title>
			<Typography level="h2" component="h1" display={'flex'} alignItems={'center'} gap={1}>
				<Skeleton loading variant='text' level='h2' sx={{ width: '300px' }}></Skeleton>
			</Typography>
			<Box display="flex" alignItems="center" gap={1}>
				<Button size='sm' variant="outlined" loadingIndicator={<CircularProgress />} disabled>{t('Update')}</Button>
				<Button disabled size='sm' variant="outlined">{t('Export analytics')}</Button>
			</Box>
		</Title>
	);
};


/**
 * 
 * прелоадер блока таблицы
 */
const TableBlockSkeleton = () => {
	return (
		<TableWrapper>
			{tableDescription.map(({ name }) => <td key={name}><Skeleton variant='text'></Skeleton></td>)}
		</TableWrapper>
	)
}

export default AdvertisersView;