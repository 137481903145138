import { useState, useEffect } from "react";
import { Box, Button, Input, Typography } from "@mui/joy";

import { useLanguageContext } from "../context/LanguageContext";

import { useDateFromParam, useDateToParam } from "../utils/hooks/paramsHelpers";


// вызвает action(from,to)
export function SelectPeriod({ loading, action, label }) {
    const { t } = useLanguageContext();
    const [from, setFrom] = useDateFromParam(e => e.target.value);
    const [to, setTo] = useDateToParam(e => e.target.value);


    const [showInfo, setShowInfo] = useState(false);
    const [timer, setTimer] = useState(false);
    const [timet, setTimet] = useState(false);
    let buttLabel = t('Show statistics for selected period');

    useEffect(() => {
        if (showInfo && !loading) {
            setTimer(3);
        } else {
            setTimer(false);
            if (timet) clearTimeout(timet)
        }
    }, [showInfo]);

    useEffect(() => {
        if (timer === 0) setTimer(false);
        else if (timer) setTimet(setTimeout(() => setTimer(timer - 1), 1000));
    }, [timer]);


    return <Box display={'flex'} alignItems={'center'} gap={1} flexDirection={{ xs: 'column', md: 'row' }} >
        <Box display={'flex'} alignItems={{ xs: 'flex-start', sm: 'center' }} gap={1} sx={{ width: { xs: '100%', md: 'auto' } }} flexDirection={{ xs: 'column', sm: 'row' }}>
            <Typography level="body-sm">From:</Typography>
            <Input disabled={loading} type={'date'} size={'sm'} value={from} onChange={setFrom} />
            <Typography level="body-sm">To:</Typography>
            <Input disabled={loading} type={'date'} size={'sm'} value={to} onChange={setTo} />
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={{ xs: 'flex-start', md: 'flex-end' }} onMouseEnter={() => setShowInfo(false)} onMouseLeave={() => setShowInfo(false)} sx={{ width: { xs: '100%', md: 'auto' } }}>
            <Typography level="body-sm" style={{ transition: 'all 0.2s ease-out', overflow: 'hidden', opacity: showInfo ? 1 : 0, height: showInfo ? '32px' : 0, fontWeight: 'bold' }}>{label}</Typography>
            <Button size={'sm'} onClick={action.bind(null, from, to)} variant="outlined" loading={loading} color="primary" disabled={!!timer || loading}>{timer ? `I understand ${timer}...` : buttLabel}</Button>
        </Box>
    </Box>
}