import { useEffect, useState } from "react";

function stopableInterval(fn, delay) {
    let interval = {
        stop: false,
        tries: 0,
        async loop() {
            while (true) {
                if (this.stop) return;
                let stop = await fn(this);
                this.tries += 1;
                if (stop) this.stop = true;
                await new Promise(r => setTimeout(r, delay));
            }
        }
    }
    interval.loop();
    return interval;
}
/**
 * request возвращает true когда нужно остановиться
 */
export function usePending(request, delay, deps) {
    useEffect(() => {
        let s_int = stopableInterval(request, delay);
        const stop = () => {
            s_int.stop = true;
        };
        return stop;
    }, deps)

}