import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useCounter } from '../utils/hooks/useCounter';
export const ParamsContext = createContext();
export function useParamsContext() {
    return useContext(ParamsContext)
}

function eq_SearchParams(params1, params2) {
    if (params1.size != params2.size) return false;
    for (let key of params1.keys()) {
        if (params1.get(key) != params2.get(key)) return false;
    }
    return true;
}
export function ParamsProvider({ children }) {
    const [flush_params, _setFlushParams] = useCounter(); // delayed signal for external code
    const [flush_params_inner, setFlushParams_inner] = useCounter(); // signal to update url
    const [searchParams, setSearchParams] = useState(new URLSearchParams(window.location.search));
    const flushParams = useCallback(async () => {
        // задержка, чтобы параметры успели проставиться перед следующим действием
        setFlushParams_inner();
        await new Promise(r => setTimeout(r, 10))
        _setFlushParams();
    }, [_setFlushParams])
    const [react_searchParams, react_setSearchParams] = useSearchParams();
    useEffect(() => {
        if (eq_SearchParams(react_searchParams, searchParams)) return;
        // когда меняется url применяем изменения в локальную копию
        setSearchParams(new URLSearchParams(react_searchParams));
        setTimeout(() => flushParams(), 10);
    }, [react_searchParams])
    useEffect(() => {
        if (eq_SearchParams(react_searchParams, searchParams)) return;
        // когда вызывают flushParams сохраняем локальную копию в url
        react_setSearchParams(() => new URLSearchParams(searchParams));
    }, [flush_params_inner])

    return (
        <ParamsContext.Provider value={{
            flush_params,
            flushParams,
            searchParams,
            setSearchParams
        }}>
            {children}
        </ParamsContext.Provider>
    );
}