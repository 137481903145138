import CyrillicToTranslit from 'cyrillic-to-translit';

export function openSidebar() {
  if (typeof window !== 'undefined') {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
  }
}

export function closeSidebar() {
  if (typeof window !== 'undefined') {
    document.documentElement.style.removeProperty('--SideNavigation-slideIn');
    document.body.style.removeProperty('overflow');
  }
}

export function toggleSidebar() {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--SideNavigation-slideIn');
    if (slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
}
export function snakeToCamelCase(str) {
  let parts = str.split('_')
  let first = parts[0]
  let rest = parts.slice(1).map(part => capitalizeFirstLetter(part))
  return [first, ...rest].join("")
}
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const cyrillicToTranslitTJ = new CyrillicToTranslit({ preset: "tj" });
const cyrillicToTranslitRU = new CyrillicToTranslit({ preset: "ru" });
export function toSlug(str) {
  let translit = cyrillicToTranslitTJ.transform(str, '-');
  translit = cyrillicToTranslitRU.transform(str, '-');
  translit = translit.toLowerCase()
  translit = translit.replace(/[^a-z0-9\-]/g, '')
  translit = translit.replace(/\-+/g, '-'); // --- => -
  if (translit.endsWith('-')) translit = translit.slice(0, -1)
  return translit
}
export function updateErrors(field, error, setErrors) {
  setTimeout(() => setErrors(errors => ({ errors, [field]: error })), 10)
}
export function formatPrc(value, end="%"){
  let num = parseFloat(value)
  if(isNaN(num)) return ""
  return (num * 100).toFixed(2) + end;
}