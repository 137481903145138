import { Crud } from ".";
import { getSearchParam } from "../hooks/paramsHelpers";

export class GroupApi extends Crud {
    constructor() {
        super('admin_sitegroup')
    }
    async get(listContext, ...args) {
        listContext = JSON.parse(JSON.stringify(listContext));
        listContext.filter = {
            search: getSearchParam(),
        }
        return await super.get(listContext, ...args);
    }
    findAnalytics(id, options) {
        return this.find(id, options, 'findAnalytics')
    }
    findAnalyticsAll(options) {
        return this.find(0, options, 'findAnalyticsAll')
    }
    monthlyVisits(listContext, id, options = {}) {
        return this.get(listContext, { id, ...options }, 'monthlyVisits')
    }
}
