// Функция для выполнения запроса с интервалом и таймаутом, пока в ответе не будет ни одного поля с pending
// При каждой итерации заполняет поля объекта
export async function executeWithIntervalAndTimeoutAndFill(fn, interval, timeout, params, setParams, objectkey = null) {
    const result = await fn(true);
    setParams({ ...params, ...(objectkey ? result[objectkey] : result) });
    if (!Object.values(objectkey ? result[objectkey] : result).some(x => x === 'pending')) return result;
    let totalTime = 0;
    while (totalTime < timeout) {
        await new Promise((r) => setTimeout(r, interval));
        let result = await fn(false);
        setParams({ ...params, ...(objectkey ? result[objectkey] : result) });
        if (Object.values(objectkey ? result[objectkey] : result).some(x => x === 'error')) {
            throw new Error("Can't load data, please try again!");
        }
        if (!Object.values(objectkey ? result[objectkey] : result).some(x => x === 'pending')) {
            return result;
        } else {
            totalTime += interval;
        }
    }
    if (totalTime >= timeout) {
        throw new Error('The request is too long, please try to send the same request again.');
    }
}
// penging while pending:true
// in case of error fn should return {is_error:true, error}
export async function listGetPending(fn, interval, timeout) {
    let totalTime = 0;
    while (totalTime < timeout) {
        let result = await fn();
        if (!result.pending) return result;
        if (result.is_error) return result;
        await new Promise(r => setTimeout(r, interval));
        totalTime += interval;

    }
    return { is_error: true, error: new Error('The request is too long, please try to send the same request again.') }
}