import { Box, Typography } from "@mui/joy";

export const ViewField = ({ name, value, ...rest }) => {
    return (
        <Box sx={{ ...rest }}>
            <Typography fontWeight={500}>
                {name}:
            </Typography>
            <Typography component={'div'}>
                {value}
            </Typography>
        </Box>
    );
};
