import React, { useCallback, useState } from 'react';

import InfoContext from '../context/InfoContext';

export default function InfoProvider({ children }) {

  const [info, _setInfo] = useState({ type: 'success', message: '', show: false });
  const setInfo = useCallback((args) => {
    if (!args.type) args = { ...args, type: 'success' }
    _setInfo(args)
  }, [_setInfo])
  return (
    <InfoContext.Provider value={{
      info, setInfo
    }}>
      {children}
    </InfoContext.Provider>
  );
}