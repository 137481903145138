import moment from "moment";
import { useParamsContext } from "../../provider/ParamsProvider";
// update сохраняет состояние в локальный URLSearchParams в ParamsProvider
// после вызова flushParams изменения применются уже в реальном url

export const useQueryParam = (key, convert = x => x) => {
    const { searchParams, setSearchParams } = useParamsContext();
    const update = (value) => {
        setSearchParams((searchParams) => {
            if (value == undefined || !convert(value)) {
                searchParams.delete(key);
            }
            else {
                searchParams.set(key, convert(value));
            }
            return new URLSearchParams(searchParams);
        });
    }
    return [searchParams.get(key), update, searchParams]
}


export const getFromParam = (searchParams = new URLSearchParams(location.search)) => {
    return searchParams.get("from") || ''
}
export const getToParam = (searchParams = new URLSearchParams(location.search)) => {
    return searchParams.get("to") || moment().local().format('YYYY-MM-DD')
}

export const useDateFromParam = (convert = x => x) => {
    const [from, setFrom, searchParams] = useQueryParam('from', convert);
    const _set = (x) => {
        return setFrom(x)
    }
    return [getFromParam(searchParams), _set]
}
export const useDateToParam = (convert = x => x) => {
    const [to, setTo, searchParams] = useQueryParam('to', convert);
    return [getToParam(searchParams), setTo]
}

function intOrDefault(val, def = 0) {
    if (isNaN(val)) return def;
    val = parseInt(val);
    if (isFinite(val)) return val;
    return def;
}

export const getClicksGteParam = (searchParams = new URLSearchParams(location.search)) => {
    return intOrDefault(searchParams.get("clicks_gte")) || 0;
}
export const useClicksGteParam = (convert = x => x) => {
    const [clicks_gte, setClicksGte, searchParams] = useQueryParam('clicks_gte', convert);
    return [getClicksGteParam(searchParams), setClicksGte]
}

export const getHaveTaParam = (searchParams = new URLSearchParams(location.search)) => {
    return searchParams.get("have_ta") || ''
}
export const useHaveTaParam = (convert = x => x) => {
    const [have_ta, setHaveTa, searchParams] = useQueryParam('have_ta', convert);
    return [getHaveTaParam(searchParams), setHaveTa]
}

export const getHaveBotParam = (searchParams = new URLSearchParams(location.search)) => {
    return searchParams.get("have_bot") || ''
}
export const useHaveBotParam = (convert = x => x) => {
    const [have_bot, setHaveBot, searchParams] = useQueryParam('have_bot', convert);
    return [getHaveBotParam(searchParams), setHaveBot]
}

export const getSearchModeParam = (searchParams = new URLSearchParams(location.search)) => {
    return searchParams.get("search_mode") || 'or'
}
export const useSearchModeParam = (convert = x => x) => {
    const [search_mode, setSearchMode, searchParams] = useQueryParam('search_mode', convert);
    return [getSearchModeParam(searchParams), setSearchMode]
}

export const getSearchParam = (searchParams = new URLSearchParams(location.search)) => {
    return searchParams.get("search") || ''
}
export const useSearchParam = (convert = x => x) => {
    const [search, setSearch, searchParams] = useQueryParam('search', convert);
    return [getSearchParam(searchParams), setSearch]
}

export const getGroupParam = (searchParams = new URLSearchParams(location.search)) => {
    return searchParams.get("group") || ''
}
export const useGroupParam = (convert = x => x) => {
    const [group, setGroup, searchParams] = useQueryParam('group', convert);
    return [getGroupParam(searchParams), setGroup]
}

export const getAdverStatusParam = (searchParams = new URLSearchParams(location.search)) => {
    return searchParams.get("adv_status") || ''
}
export const useAdverStatusParam = (convert = x => x) => {
    const [adv_status, setAdverStatus, searchParams] = useQueryParam('adv_status', convert);
    return [getAdverStatusParam(searchParams), setAdverStatus]
}

export const getSquadTypeParam = (searchParams = new URLSearchParams(location.search)) => {
    return searchParams.get("squad_type") || ''
}
export const useSquadTypeParam = (convert = x => x) => {
    const [squad_type, setSquadType, searchParams] = useQueryParam('squad_type', convert);
    return [getSquadTypeParam(searchParams), setSquadType]
}


export const getAntifrautTypeParam = (searchParams = new URLSearchParams(location.search)) => {
    return searchParams.get("antifraud_type") || ''
}
export const useAntifrautTypeParam = (convert = x => x) => {
    const [antifraud_type, setAntifrautType, searchParams] = useQueryParam('antifraud_type', convert);
    return [getAntifrautTypeParam(searchParams), setAntifrautType]
}


export const getUserRoleParam = (searchParams = new URLSearchParams(location.search)) => {
    return searchParams.get("user_role") || ''
}
export const useUserRoleParam = (convert = x => x) => {
    const [user_role, setUserRole, searchParams] = useQueryParam('user_role', convert);
    return [getUserRoleParam(searchParams), setUserRole]
}

